import { useAuth } from '@youversion/auth';
import type { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/compat/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as React from 'react';

import { FullPageLoader } from '@/components/loaders/full-page-loader';

const Home: NextPage = () => {
  const { isSignedIn, isLoading: isLoadingAuth } = useAuth();
  const router = useRouter();

  React.useEffect(() => {
    if (isLoadingAuth) {
      return;
    } else if (!isSignedIn) {
      router?.replace('/sign-in');
    } else {
      router?.replace({
        pathname: '/organization-select',
        query: router.query,
      });
    }
  }, [isSignedIn, router, isLoadingAuth]);

  return <FullPageLoader />;
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  };
};

export default Home;
